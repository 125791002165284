import React from 'react';
import TagManager from 'react-gtm-module';

interface GtmProps {
	id: string;
}

const Gtm: React.FC<GtmProps> = ({ id }) => {
	React.useEffect(() => {
		if (process.env.NODE_ENV === 'production' && id && id.trim() !== '') {
			TagManager.initialize({ gtmId: id });
		}
	}, [id]);
	return <></>;
};

export default Gtm;
