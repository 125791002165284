import * as React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

// eslint-disable-next-line
import once from 'lodash/once';
// eslint-disable-next-line
import isEmpty from 'lodash/isEmpty';

import { Company } from '@commonTuna/react/objects/Company';

import { Location } from '@app/objects/Location';
import { ApplicationState } from '@app/store';

export interface ReduxContext {
	companySettings: Company;
	location: Location;
	blogPageId: number;
}

export const createReduxContext = once(() => React.createContext({} as ReduxContext));

export const useReduxContext: () => ReduxContext = () => {
	const context: ReduxContext = React.useContext<ReduxContext>(createReduxContext());

	if (isEmpty(context)) throw 'Need Redux Context!';

	return context;
};

const ReduxProvider: React.FC = ({
	children,
}) => {
	const ReduxProvider = createReduxContext();

	const value = useSelector((state: ApplicationState) => ({
		companySettings: state.location.item.company,
		location: state.location.item,
		blogPageId: state.blogPageId.item,
	}), shallowEqual);

	return (
		<>
			<ReduxProvider.Provider value={value}>
				{typeof children === 'function' ? children(value) : children}
			</ReduxProvider.Provider>
		</>
	);
};

export default ReduxProvider;
