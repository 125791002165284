import * as React from 'react';

import { RequestProvider } from '@common/react/components/RequestProvider/RequestProvider';
import { ErrorBoundaryWithLocation } from '@common/react/components/UI/ErrorBoundary/ErrorBoundary';
import Application from '@common/react/components/Core/Application/Application';

import Gtm from '@app/components/UI/Gtm/Gtm';
import RouteChangeTracker from '@app/components/Routes/RouteChangeTracker';
import ReduxProvider from '@app/hooks/useReduxContext';

import '@app/scss/style.scss';

const Layout: React.FC = ({ children }) => <div className="app-container">
	<ErrorBoundaryWithLocation>
		<ReduxProvider>
			{({ companySettings: { googleAnalyticsId, googleTagManagerId } }) =>
				<RouteChangeTracker id={googleAnalyticsId}>
					<RequestProvider>
						<Application
							withoutSignalR
						>
							<div className="content">{children}</div>
							<Gtm id={googleTagManagerId} />
						</Application>
					</RequestProvider>
				</RouteChangeTracker>
			}
		</ReduxProvider>
	</ErrorBoundaryWithLocation>
</div>;

export default Layout;
